import React from 'react'
import ThoughtGrid from '../components/gridBlocks/thoughtGrid'

export default function Thoughts() {
  return (
    <>
      <ThoughtGrid />
    </>
  )
}
